import './bootstrap';
import '../css/app.css';
//import 'quasar/src/css/index.sass';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { Quasar } from 'quasar'; 
import VueECharts from 'vue-echarts';
import * as echarts from 'echarts';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';

// Import Quasar css
import 'quasar/src/css/index.sass';

/*import { Quasar } from 'quasar'; */
import quasarUserOptions from './quasar-user-options'; // Optional

// Import ECharts modules
import { HeatmapChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, VisualMapComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

import { Notify, Dialog } from 'quasar';

import langDe from 'quasar/lang/de'

// Use ECharts modules
echarts.use([HeatmapChart, GridComponent, TooltipComponent, VisualMapComponent, CanvasRenderer]);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(Quasar, {
                lang: langDe,
                ...quasarUserOptions,
                plugins: { Notify, Dialog } // Enable Notify plugin
            })
            .component('vue-chart', VueECharts)
            .component('vue-cal-custom', VueCal)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
